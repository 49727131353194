import axios from 'axios';
export default {
  namespaced: true,
  state: {
    payment: []
  },
  mutations: {
    setPayment(state, payload) {
      state.payment.push(payload);
    }
  },
  actions: {
    async getPaymentByCode({ commit }, code) {
      try {
        const payment = await axios.get(`/payment/get-payment-code/${code}`);
        commit('setPayment', payment.data.data);
      } catch (error) {
        throw error
      }
    }
  },
  getters: {}
}
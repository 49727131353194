import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Register',
    component: () => import('../views/register/Register.vue')
  },
  {
    path: '/pagar/:code',
    name: 'Payment',
    component: () => import('../views/register/Payment.vue')
  },
  {
    path: '/message/:code/:order',
    name: 'Message',
    component: () => import('../views/register/Message.vue')
  },
  {
    path: '/buscar-pago',
    name: 'PaymentFind',
    component: () => import('../views/register/PaymentFind.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'
import Lang from './modules/Lang'
import Catalogs from './modules/Catalogs'
import Register from './modules/Register'
import Payments from './modules/Payments'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Lang,
    Catalogs,
    Register,
    Payments
  }
})


export default {
  namespaced: true,
  state: {
    register: []
  },
  mutations: {
    setRegister(state, payload) {
      state.register = payload;
    },
  },
  actions: {
    register({ commit }, data) {
      commit('setRegister', data);
    }
  },
  getters: {
    
  }
}
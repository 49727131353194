import axios from 'axios';

export default {
  namespaced: true,
  state: {
    listSchool: []
  },
  mutations: {
    setListSchool(state, payload) {
      state.listSchool = payload
    }
  },
  actions: {
    /**
     * @description Accion que realiza una llamada al API para obtener el listado de estableciminetos
     * activos para ser usados en el formulario.
     * @param {commit} param0 
     */
    async getListSchool({ commit }) {
      try {
        const list = await axios.get('/catalog/listar-colegios');
        commit('setListSchool', list.data.data);
      } catch (error) {
        throw error
      }
    }
  },
  getters: {
    /**
     * @description Getter que crea una array de establecimientos para el box de 
     * colegios en formulario de registro inicial.
     * @param {State} state 
     * @returns Array 
     */
    listSchoolGetter(state) {

      let lista = [];

      state.listSchool.forEach(element => {
        lista.push({ label: element._school, id: element.id })
      });

      return lista;
    }
  }
}
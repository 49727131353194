const Lang = {
  login: {
    title: 'Registro Psicometrix',
    subtitle: 'Ingresa información válida para registrarte en nuestra plataforma y realizar nuestras evaluaciones.',
    inputs: {
      first_name: 'Nombres',
      first_name_example: 'Ejemp.: Pedro Antonio',
      last_name: 'Apellidos',
      last_name_example: 'Ejemp.: Rodriguez Aguilar',
      email: 'Email',
      email_example: 'Ejemp.: Test@gmail.com',
      email_text_1: 'A este correo enviaremos tu reporte.',
      dni: 'Rut o pasaporte',
      dni_example_1: 'Ejemp.: 0000000-0',
      dni_example_2: 'Ejemp.: P0000000 ó 0000000',
      dni_small_text_1: 'Para RUT debes ingresar el siguiente formato 00000000-0 ',
      dni_small_text_2: 'Para otro tipo de identificación puedes usar letras y números P000000 o 0000000. ',
      school: 'Colegio',
      representative: 'Nombres',
      representative_label: 'Ingrese el nombre del apoderado, responsable o tutor legal del alumno, de no poseer colocar los mismos datos del alumno.',
      phone_student: 'Teléfono alumno',
      phone_representative: 'Teléfono',
      radio_label: 'Selecciona el tipo de identificación:',
      radio_rut: "RUT",
      radio_other: "Pasaporte u Otra identificación",
      button_register: 'Registrarse',
      button_register_save: 'Guardando...',
      text_complete_pay: '¿Completa el pago de tu compra?',
      link_complete_pay: 'Click Aqui',
      button_find: 'Buscar',
      button_find_save: 'Buscando...',
    },
    validations: {
      error_first_name: 'Los nombres es requerido',
      error_last_name: 'Los apellidos son requeridos',
      error_email: 'El email es requerido',
      error_email_format: 'El formato de email es incorrecto',
      error_dni: 'El RUT es requrido',
      error_dni_format: 'El formato del RUT es incorrecto',
      error_dni_min: 'El RUT posee menos caracteres de lo esperado',
      error_dni_max: 'El RUT posee mas caracteres de lo esperado',
      error_dni_other: 'El Documento de identidad es requerido',
      error_dni_other_min: 'El formato de Documento de identidad tiene muy pocos caracteres',
      error_dni_other_format: 'El formato de Documento de identidad es incorrecto',
      error_representative: 'El apoderado es requerido',
      error_school: 'El colegio es requerido'
    },
    notifications: {
      email_warning_title: '¡Ha ocurrido un problema!',
      email_warnini_subtitle: '',
      pay_warning_title: '¡Atención!',
    },

  }
}



module.exports = Lang;

import Lang from '../../lang/Es-es';
const useLang = 1;

export default {
  namespaced: true,
  state: {
    lang: Lang 
  },
  mutations: {},
  actions: {},
  getters: {}
}